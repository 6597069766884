<template>
<div class="">
    <userIndex role="user" />
</div>
</template>


<script>
import userIndex from '../common/userindexlayout.vue'
export default {

  name: 'app',
  components: {
    userIndex,
  },


}
</script>